import { useState, useEffect } from "react";

const AnimateText = ({ text, speed, expand }) => {
  const [typedText, setTypedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (expand) {
      const typingInterval = setInterval(() => {
        if (currentIndex < text.length) {
          setTypedText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          clearInterval(typingInterval);
        }
      }, speed);

      return () => {
        clearInterval(typingInterval);
      };
    }
  }, [text, speed, currentIndex, expand]);

  return <>{typedText}</>;
};

export default AnimateText;
