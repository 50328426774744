import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TabButton, TabContainer } from "../../Assets/Styles/Pages/Tabs";

function Tabs({ value }) {
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;

  const HandleNavigate = (val) => {
    navigate(val.url);
    return;
  };

  return (
    <TabContainer>
      {value.map((val, i) => {
        return (
          <TabButton
            key={val.heading}
            onClick={() => {
              HandleNavigate(val);
            }}
            active={val.url === url}
          >
            {val.heading}
          </TabButton>
        );
      })}
    </TabContainer>
  );
}

export default Tabs;
