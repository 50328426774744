import styled from "styled-components";

export const TabContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--highlighting-background);
  width: max-content;
  border-radius: 0.5rem;

  @media (max-width: 76.8em) {
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const TabButton = styled.button`
  width: 16rem;
  text-align: center;
  padding: 0.8rem 0rem;
  border-radius: 0.5rem;
  border: none;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;

  box-shadow: ${({ active }) => {
    return active
      ? "rgba(var(--background), 0.05) 0rem 0.2rem 0.8rem 0rem"
      : "0";
  }};

  background: ${({ active }) => {
    return !active ? "transparent" : "var(--background)";
  }};

  color: ${({ active }) => {
    return !active ? "var(--text-primary)" : "var(--text-secondary)";
  }};

  @media (max-width: 76.8em) {
    font-size: 1.1rem;
  }
`;
