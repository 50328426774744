import {
  ProfileContainer,
  RestrictWidth,
  StyledInput,
  StyledHeading,
  InputLabel,
  InputGroup,
  FlexContainer,
} from "../../Assets/Styles/Pages/Profile";
import { decryptData } from "../../Utils/API/cryptoUtil";

function Profile() {
  const userData = decryptData(sessionStorage.getItem("user"));
  return (
    <ProfileContainer>
      <RestrictWidth>
        <StyledHeading>Profile Details</StyledHeading>
        <FlexContainer>
          <InputGroup>
            <InputLabel htmlFor="name">Name</InputLabel>
            <StyledInput
              id="name"
              label="Name"
              value={`${userData.fname} ${userData.lname}`}
              disabled
            />
          </InputGroup>
          {/* <InputGroup>
            <InputLabel htmlFor='e_id'>Employee ID</InputLabel>
            <StyledInput id='e_id' value={userData.e_id} disabled />
          </InputGroup> */}
          <InputGroup>
            <InputLabel htmlFor="email">Email</InputLabel>
            <StyledInput value={userData.email} disabled />
          </InputGroup>
        </FlexContainer>
      </RestrictWidth>
    </ProfileContainer>
  );
}

export default Profile;
