import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./Components/AuthPages/Login";
// import Signup from "./Components/AuthPages/Signup";
import Dashboard from "./Components/Pages/Dashboard";
// import ResetPassword from "./Components/AuthPages/ResetPassword";
// import Verification from "./Components/AuthPages/Verification";
import { ToastContainerWrapper } from "./Assets/Styles/Pages/Toast";

function App() {
  const AuthRoute = ({ children }) => {
    if (sessionStorage.getItem("token")) {
      return children;
    }

    return <Navigate to={"/login"} />;
  };

  const UnknownRoute = () => {
    if (sessionStorage.getItem("token")) {
      return <Navigate to={"/"} />;
    }

    return <Navigate to={"/login"} />;
  };

  return (
    <>
      <ToastContainerWrapper
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
      />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path='/signup' element={<Signup />} />
          <Route path='/reset_password/*' element={<ResetPassword />} />
          <Route path='/verify_account/*' element={<Verification />} /> */}
          <Route
            path="/*"
            element={
              <AuthRoute>
                <Dashboard />
              </AuthRoute>
            }
          />

          <Route path="*" element={<UnknownRoute />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
