import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";

export const ToastContainerWrapper = styled(ToastContainer)`
  position: fixed;
  bottom: 5rem;
  right: 5rem;
  padding: 2rem;
  font-size: 1.6rem;
`;
