import FileUpload from "../../Models/FileUpload";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import {
  StyledInput,
  InputContainer,
  HeaderButton,
  StyledHeading,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledTableCell,
  ErrorButton,
  FilesContainer,
} from "../../../Assets/Styles/Pages/Files";
import { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import { TbSortAscending, TbSortDescending } from "react-icons/tb";
import AlertDialog from "../../Models/AlertDialog";
import { useMemo } from "react";
import api, { setNavigateFunction } from "../../../Utils/API/ApiInterceptor";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { decryptData } from "../../../Utils/API/cryptoUtil";

function Files() {
  const columns = [
    {
      id: "file_name",
      label: "File Name",
      align: "center",
    },
    {
      id: "uploaded_on",
      label: "Uploaded On",
      align: "center",
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [pdfData, setPdfData] = useState([]);
  const [sorting, setSorting] = useState({
    file_name: "asc",
    uploaded_on: "asc",
  });
  const [open, setOpen] = useState(false);
  const [selectedPdfId, setSelectedPdfId] = useState(null);
  const [selectedActionType, setSelectedActionType] = useState(null);
  const navigate = useNavigate();
  setNavigateFunction(navigate);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserActionConfirmation = async (actionType) => {
    if (selectedPdfId) {
      try {
        let response;
        if (actionType === "remove") {
          response = await api.post(
            `${process.env.REACT_APP_BASE_URL}/admin/delete_policy`,
            {
              id: selectedPdfId,
              user_email: decryptData(sessionStorage.getItem("user")).email,
            }
          );
        }

        if (response.status === 200) {
          await fetchPdfData();
          toast.success(response?.data?.message);
        }
      } catch (err) {
        toast.error(err?.response?.data?.error);
      }
      setSelectedPdfId(null);
      setSelectedActionType(null);
      handleClose();
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleSorting = (columnId) => {
    setSorting((prevSorting) => ({
      ...prevSorting,
      [columnId]: prevSorting[columnId] === "asc" ? "desc" : "asc",
    }));

    const sortedData = [...pdfData].sort((a, b) => {
      const aValue = a[columnId];
      const bValue = b[columnId];

      if (aValue !== bValue) {
        const sortOrder = sorting[columnId] === "asc" ? 1 : -1;
        return aValue.localeCompare(bValue) * sortOrder;
      }
      return 0;
    });

    setPdfData(sortedData);
    setPage(0);
  };

  const fetchPdfData = useMemo(() => {
    return async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_BASE_URL}/admin/show_pdfs_list`,
          {
            headers: {
              user_email: decryptData(sessionStorage.getItem("user")).email,
            },
          }
        );
        if (response.status === 200) {
          setPdfData(response?.data?.pdf_list);
        }
      } catch (err) {
        toast.error(err?.response?.data?.error);
      }
    };
  }, []);

  useEffect(() => {
    fetchPdfData();
  }, [fetchPdfData]);

  return (
    <>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        handleConfirmation={handleUserActionConfirmation}
        actionType={selectedActionType}
        content="pdf from knowledge base"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: "2rem",
          marginBottom: "0rem",
        }}
      >
        {" "}
      </div>

      <FilesContainer>
        <StyledHeading>
          <div style={{ marginBottom: "2rem" }}>
            <FileUpload fetchPdfData={fetchPdfData} />
          </div>
          <div>File Details</div>
        </StyledHeading>
        <InputContainer>
          <div
            style={{
              position: "relative",
            }}
          >
            <FaSearch
              style={{
                color: "var(--text-secondary)",
                position: "absolute",
                top: "50%",
                right: "3%",
                width: "2.2rem",
                height: "2.2rem",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            />
            <StyledInput
              placeholder="Search Files"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
        </InputContainer>
        <StyledTableContainer component={Paper}>
          <StyledTable sx={{ marginTop: 2 }} aria-label="files table">
            <TableHead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 5,
                background: "white",
              }}
            >
              <TableRow>
                {columns.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                        padding: "0.5rem",
                        cursor: "pointer",
                      }}
                      align={column.align}
                      onClick={() => handleSorting(column.id)}
                    >
                      <HeaderButton>
                        {column.label}
                        {sorting[column.id] === "asc" && <TbSortDescending />}
                        {sorting[column.id] === "desc" && <TbSortAscending />}
                      </HeaderButton>
                    </TableCell>
                  );
                })}
                <TableCell align="left" style={{ minWidth: "5%" }}>
                  {""}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pdfData
                ?.filter((row) => {
                  return columns.some((column) => {
                    switch (column.id) {
                      case "uploaded_on":
                        return dayjs(row[column.id])
                          .format("DD-MM-YYYY")
                          .toString()
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase());
                      default:
                        return row[column.id]
                          .toString()
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase());
                    }
                  });
                })
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) =>
                  index % 2 === 0 ? (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        let value;
                        if (column.id === "uploaded_on") {
                          value = dayjs(row.uploaded_on).format("DD-MM-YYYY");
                        } else {
                          value = row[column.id];
                        }
                        return (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            sx={{ fontSize: "1.2rem", padding: "0.5rem" }}
                          >
                            {value}
                          </StyledTableCell>
                        );
                      })}
                      <StyledTableCell align="center">
                        <ErrorButton
                          onClick={() => {
                            setSelectedPdfId(row.id);
                            setSelectedActionType("remove");
                            handleClickOpen();
                          }}
                        >
                          Remove
                        </ErrorButton>
                      </StyledTableCell>
                    </TableRow>
                  ) : (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      {columns.map((column) => {
                        let value;
                        if (column.id === "uploaded_on") {
                          value = dayjs(row.uploaded_on).format("DD-MM-YYYY");
                        } else {
                          value = row[column.id];
                        }
                        return (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            sx={{ fontSize: "1.2rem", padding: "0.5rem" }}
                          >
                            {value}
                          </StyledTableCell>
                        );
                      })}
                      <StyledTableCell align="center">
                        <ErrorButton
                          onClick={() => {
                            setSelectedPdfId(row.id);
                            setSelectedActionType("remove");
                            handleClickOpen();
                          }}
                        >
                          Remove
                        </ErrorButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                )}
            </TableBody>
          </StyledTable>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={pdfData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </StyledTableContainer>
      </FilesContainer>
    </>
  );
}

export default Files;
