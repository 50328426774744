import { Input } from "@mui/material";
import styled from "styled-components";

export const QuestionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem 0rem;
  width: 100%;
  overflow-x: auto;
`;

export const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0rem 8rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.6875rem;
  color: var(--text-secondary);

  @media (max-width: 76.8em) {
    padding: 0rem 2rem;
  }
`;

export const AnswerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: var(--highlighting-background);
  padding: 3rem 0rem;
  width: 100%;
  overflow-x: auto;
`;

export const AnswerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0rem 8rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.6875rem;
  color: var(--text-secondary);

  @media (max-width: 76.8em) {
    padding: 0rem 2rem;
  }
`;

export const ChatResponseBox = styled.div`
  flex-basis: 85%;
  padding: 2rem 0rem;
  overflow-y: auto;
`;

export const ShowSelectedChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--right-bg);
  padding: 2rem 0rem;
`;

export const InputContainer = styled.div`
  flex: 1;
  padding: 2rem 5rem;
  align-items: center;
  justify-content: center;

  @media (max-width: 76.8em) {
    padding: 2rem 1rem;
  }
`;

export const StyledInput = styled(
  ({ endAdornment, forwardedRef, ...props }) => (
    <Input
      {...props}
      endAdornment={endAdornment}
      inputRef={forwardedRef}
      sx={{
        fontSize: "1.3rem",
        color: "var(--text-primary)",
        width: "100%",
        borderRadius: "0.5rem",
        outline: "none",
        overflow: "hidden",
        padding: "2.2rem 2rem",
        border: "none",
        height: "4rem",
        background: "var(--highlighting-background)",
        transition: "all 0.2s linear",
      }}
    />
  )
)``;
