import { createSlice } from "@reduxjs/toolkit";

const chatHistorySlice = createSlice({
  name: "chatHistory",
  initialState: [],
  reducers: {
    insertHistory: (state, action) => {
      return [...state, action.payload];
    },
    chatHistoryPop: (state) => {
      return state.slice(0, -1);
    },

    deleteChat: (state, action) => {
      return state.filter((item) => {
        return item !== action.payload;
      });
    },
    flushHistory: (state) => {
      return [];
    },
  },
});

export const { insertHistory, flushHistory, chatHistoryPop, deleteChat } =
  chatHistorySlice.actions;
export default chatHistorySlice.reducer;
