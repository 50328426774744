import { configureStore } from "@reduxjs/toolkit";
import expandSidebarSlice from "../expandSidebarSlice";
import chatHistorySlice from "../chatHistorySlice";

export const store = configureStore({
  reducer: {
    sidebar: expandSidebarSlice,
    chatHistory: chatHistorySlice,
  },
});
