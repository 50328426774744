import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import {
  StyledInput,
  InputContainer,
  HeaderButton,
  StyledHeading,
  StyledTableContainer,
  StyledTable,
  StyledTableRow,
  StyledTableCell,
  QueriesContainer,
} from "../../../Assets/Styles/Pages/AllQueries";
import { useState, useEffect, useMemo } from "react";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import { TbSortAscending, TbSortDescending } from "react-icons/tb";
import dayjs from "dayjs";
import api, { setNavigateFunction } from "../../../Utils/API/ApiInterceptor";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../../Utils/API/cryptoUtil";

function AllQueries() {
  const columns = [
    {
      id: "title",
      label: "Title",
      align: "center",
      sortingOrder: null,
    },
    {
      id: "description",
      label: "Description",
      align: "center",
      sortingOrder: null,
    },
    {
      id: "submitted_on",
      label: "Submitted On",
      align: "center",
      sortingOrder: null,
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [feedbackData, setFeedbackData] = useState([]);
  const [sorting, setSorting] = useState({
    title: "asc",
    description: "asc",
    positive_feedback: "asc",
    submitted_on: "asc",
  });
  const navigate = useNavigate();
  setNavigateFunction(navigate);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  const handleSorting = (columnId) => {
    setSorting((prevSorting) => ({
      ...prevSorting,
      [columnId]: prevSorting[columnId] === "asc" ? "desc" : "asc",
    }));

    setFeedbackData((prevFeedbackData) => {
      const sortedData = [...prevFeedbackData].sort((a, b) => {
        const aValue = a[columnId].toString().toLowerCase();
        const bValue = b[columnId].toString().toLowerCase();

        if (aValue !== bValue) {
          let sortOrder = sorting[columnId] === "asc" ? 1 : -1;
          return aValue.localeCompare(bValue) * sortOrder;
        }
        return 0;
      });
      return sortedData;
    });
    setPage(0);
  };

  const fetchAllFeedbacks = useMemo(() => {
    return async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_BASE_URL}/admin/all_queries`,
          {
            headers: {
              user_email: decryptData(sessionStorage.getItem("user")).email,
            },
          }
        );
        if (response.status === 200) {
          setFeedbackData(response?.data?.content);
        }
      } catch (err) {
        toast.error(err?.response?.data?.error);
      }
    };
  }, []);

  useEffect(() => {
    fetchAllFeedbacks();
  }, [fetchAllFeedbacks]);

  return (
    <QueriesContainer>
      <StyledHeading>Queries</StyledHeading>
      <InputContainer>
        <div
          style={{
            position: "relative",
          }}
        >
          <FaSearch
            style={{
              color: "var(--text-secondary)",
              position: "absolute",
              top: "50%",
              right: "3%",
              width: "2rem",
              height: "2rem",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
          />
          <StyledInput
            placeholder='Search Queries'
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </InputContainer>
      <StyledTableContainer component={Paper}>
        <StyledTable sx={{ marginTop: 2 }} aria-label='feedback table'>
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              zIndex: 5,
              background: "white",
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    padding: "0.5rem",
                    cursor: "pointer",
                  }}
                  align={column.align}
                  onClick={() => handleSorting(column.id)}
                >
                  <HeaderButton>
                    {column.label}
                    {sorting[column.id] === "asc" && <TbSortDescending />}
                    {sorting[column.id] === "desc" && <TbSortAscending />}
                  </HeaderButton>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbackData
              .filter((row) =>
                columns.some((column) => {
                  switch (column.id) {
                    case "submitted_on":
                      return dayjs(row[column.id])
                        .format("DD-MM-YYYY h:mm A")
                        .toString()
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase());
                    default:
                      return row[column.id]
                        .toString()
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase());
                  }
                })
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const rowKey = `row-${index}`;
                return index % 2 === 0 ? (
                  <TableRow hover role='checkbox' tabIndex={-1} key={rowKey}>
                    {columns.map((column) => {
                      let value;
                      if (column.id === "submitted_on") {
                        value = dayjs(row.submitted_on).format(
                          "DD-MM-YYYY h:mm A"
                        );
                      } else {
                        value = row[column.id];
                      }
                      return (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          sx={{ fontSize: "1.2rem" }}
                        >
                          {value}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                ) : (
                  <StyledTableRow
                    hover
                    role='checkbox'
                    tabIndex={-1}
                    key={rowKey}
                  >
                    {columns.map((column) => {
                      let value;
                      if (column.id === "submitted_on") {
                        value = dayjs(row.submitted_on).format(
                          "DD-MM-YYYY h:mm A"
                        );
                      } else {
                        value = row[column.id];
                      }
                      return (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          sx={{ fontSize: "1.2rem" }}
                        >
                          {value}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </StyledTable>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component='div'
          count={feedbackData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledTableContainer>
    </QueriesContainer>
  );
}

export default AllQueries;
