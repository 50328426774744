import {
  ContactUsContainer,
  RestrictWidth,
  StyledInput,
  StyledButton,
  StyledHeading,
  InputLabel,
  InputGroup,
  FlexContainer,
  StyledTextField,
  ButtonContainer,
} from "../../Assets/Styles/Pages/ContactUs";
import { useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { decryptData } from "../../Utils/API/cryptoUtil";
import api, { setNavigateFunction } from "../../Utils/API/ApiInterceptor";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const userData = decryptData(sessionStorage.getItem("user"));
  const navigate = useNavigate();
  setNavigateFunction(navigate);

  const [params, setParams] = useState({
    title: "",
    description: "",
    positive_feedback: true,
    user_id: userData.e_id,
  });
  const [isLoading, setIsLoading] = useState(false);

  const onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "positive_feedback") {
      value = !params.positive_feedback;
    }
    setParams({
      ...params,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!params.title || !params.description) {
      toast.error("Please enter all the details");
      return;
    }
    try {
      setIsLoading(true);
      const response = await api.post(
        `${process.env.REACT_APP_BASE_URL}/user/add_customer_query`,
        {
          ...params,
          user_email: decryptData(sessionStorage.getItem("user")).email,
        }
      );
      setIsLoading(false);
      if (response.status === 200) {
        setParams({
          ...params,
          title: "",
          description: "",
          positive_feedback: true,
        });
        toast.success(response.data.message);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.error);
    }
  };

  return (
    <>
      <ContactUsContainer>
        <RestrictWidth>
          <StyledHeading>Contact Us</StyledHeading>
          <FlexContainer>
            <form onSubmit={handleSubmit}>
              <InputGroup>
                <InputLabel htmlFor="title">Title</InputLabel>
                <StyledInput
                  name="title"
                  label="Title"
                  autoComplete="off"
                  onChange={onChangeHandler}
                  value={params.title}
                />
              </InputGroup>
              <InputGroup>
                <InputLabel htmlFor="title">Description</InputLabel>
                <StyledTextField
                  name="description"
                  autoComplete="off"
                  onChange={onChangeHandler}
                  value={params.description}
                  multiline
                  minRows={6}
                  maxRows={12}
                />
              </InputGroup>
              <ButtonContainer>
                {isLoading ? (
                  <CircularProgress
                    style={{
                      color: "var(--text-secondary)",
                      width: "4rem",
                      height: "4rem",
                      cursor: "pointer",
                      position: "relative",
                      top: "3rem",
                    }}
                  />
                ) : (
                  <StyledButton type="submit">Submit</StyledButton>
                )}
              </ButtonContainer>
            </form>
          </FlexContainer>
        </RestrictWidth>
      </ContactUsContainer>
    </>
  );
};
export default ContactUs;
