import { createSlice } from "@reduxjs/toolkit";

const expandSidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    sidebarState: false,
  },
  reducers: {
    expandSidebar: (state) => ({
      ...state,
      sidebarState: true,
    }),
    collapseSidebar: (state) => ({
      ...state,
      sidebarState: false,
    }),
  },
});

export const { expandSidebar, collapseSidebar } = expandSidebarSlice.actions;
export default expandSidebarSlice.reducer;
