import { Button } from "@mui/material";
import styled from "styled-components";

export const ChatContainer = styled.div`
  display: flex;
  height: 100%;
  background: var(--background);
`;

export const ChatHistoryButton = styled(Button)`
  width: 100%;
  color: var(--text-secondary) !important;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  background: var(--highlighting-background) !important;
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
  font-size: 1.2rem !important;
  font-weight: bold;
  text-transform: none !important;
`;

export const CreateChatBtn = styled(Button)`
  width: 100%;
  color: var(--text-secondary) !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  gap: 0.5rem;
  // background: var(--highlighting-background) !important;
  padding: 0.65rem 2rem !important;
  font-size: 1.2rem !important;
  font-weight: bold;
  text-transform: none !important;
  border-radius: 0.5rem !important;
  border: 3px solid var(--highlighting-background) !important;
  margin-bottom: 3rem !important;
`;

export const StyledBtn = styled(Button)`
  width: 100%;
  color: var(--text-secondary) !important;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  gap: 0.5rem;
  padding: 0.65rem 1rem !important;
  font-size: 1rem !important;
  font-weight: bold;
  text-transform: none !important;
  border-radius: 0.5rem !important;
`;

export const ChatHistoryContainer = styled.div`
  flex-basis: 27rem;
  height: 100%;
  padding: 1rem;
  overflow-y: auto;

  @media (max-width: 76.8em) {
    transition: all 0.5s ease-in;
    display: none;
  }
`;

export const ConversationContainer = styled.div`
  height: 100%;
  flex: 1;
  overflow-y: auto;
`;

export const PopperDiv = styled(({ forwardedRef, ...props }) => (
  <div {...props} ref={forwardedRef} />
))`
  margin: 0.5rem 0rem;
  z-index: 999999;
  height: 8rem;
  width: 10rem;
  border-radius: 1rem;
  padding: 0.5rem;
  background: var(--background-shade-1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;
