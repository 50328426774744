import React, { useEffect, useState, useRef } from "react";
import { IoMdSend } from "react-icons/io";
import { IoIosStarHalf } from "react-icons/io";
import { BsReplyAll } from "react-icons/bs";
import {
  QuestionBox,
  AnswerBox,
  ShowSelectedChatContainer,
  ChatResponseBox,
  InputContainer,
  StyledInput,
  QuestionContainer,
  AnswerContainer,
} from "../../Assets/Styles/Pages/ShowSelectedChat";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { toast } from "react-toastify";
import api, { setNavigateFunction } from "../../Utils/API/ApiInterceptor";
import { useNavigate } from "react-router-dom";
import { IoMdMic } from "react-icons/io";
import { IoCreateOutline } from "react-icons/io5";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import { useDispatch } from "react-redux";
import { collapseSidebar } from "../../Redux/expandSidebarSlice";
import getCurrentTimestamp from "../../Utils/TimeStampUtil";
import parse from "html-react-parser";
import { decryptData } from "../../Utils/API/cryptoUtil";

const QuestionComponent = ({ question }) => {
  return (
    <QuestionBox>
      <QuestionContainer>
        <div className="user_icon">
          <IoIosStarHalf />
        </div>
        <p>{question}</p>
      </QuestionContainer>
    </QuestionBox>
  );
};

const AnswerComponent = ({ answer, shouldAnimate, containerRef }) => {
  const [typedText, setTypedText] = useState("");

  useEffect(() => {
    if (shouldAnimate) {
      const printSentence = (sentence) => {
        let index = 0;
        const speed = 10;
        let timer;
        timer = setInterval(() => {
          const char = sentence[index];
          if (char === "<") {
            index = sentence.indexOf(">", index);
          }
          setTypedText(sentence.slice(0, index));
          index++;
          if (index === sentence.length) {
            clearInterval(timer);
          }
          if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
          }
        }, speed);
      };
      printSentence(answer);
    } else {
      setTypedText(answer);
    }
  }, [answer, containerRef]);

  return (
    <AnswerBox>
      <AnswerContainer>
        <div className="user_icon">
          <BsReplyAll />
        </div>

        <p className="chat-table">{parse(typedText)}</p>
      </AnswerContainer>
    </AnswerBox>
  );
};

const ReplyComponent = ({ query, answer, shouldAnimate, containerRef }) => {
  return (
    <div className="reply_container">
      <QuestionComponent question={query} />
      <AnswerComponent
        answer={answer}
        shouldAnimate={shouldAnimate}
        containerRef={containerRef}
      />
    </div>
  );
};

function ShowSelectedChat({
  selected,
  setSelected,
  setNewChat,
  shouldAnimate,
  setShouldAnimate,
  query,
  setQuery,
  isLoading,
  setIsLoading,
  text,
  isListening,
  startListening,
  stopListening,
  setIsListening,
  hasRecognitionSupport,
}) {
  const chatHistory = JSON.parse(sessionStorage.getItem("chat_history")) || [];
  const indexOfSelected = chatHistory.findIndex((item) => item.id === selected);
  const navigate = useNavigate();
  setNavigateFunction(navigate);
  const inputRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const HandleQnA = async (queryValue, id = selected) => {
    if (queryValue.trim().length === 0)
      return toast.error("Please enter a query");
    try {
      setIsLoading(true);
      const response = await api.post(
        `${process.env.REACT_APP_BASE_URL}/user/chat`,
        {
          data: queryValue,
          user_email: decryptData(sessionStorage.getItem("user")).email,
        },
        {
          headers: {
            sessionId: id,
          },
        }
      );
      if (response.status === 200) {
        setIsLoading(false);
        setQuery("");
        const data = JSON.parse(sessionStorage.getItem("chat_history")) || [];
        const indexOfThread = data.findIndex((item) => item.id === id);
        const chatEntry = {
          query: response?.data?.query,
          message: response?.data?.message,
        };
        if (indexOfThread === -1) {
          data.unshift({
            messages: [chatEntry],
            heading: response?.data?.query,
            id: id,
            timestamp: getCurrentTimestamp(),
          });
        } else {
          data[indexOfThread].messages.push(chatEntry);
          data[indexOfThread].timestamp = getCurrentTimestamp();
        }
        data.sort((a, b) => b.timestamp - a.timestamp);
        sessionStorage.setItem("chat_history", JSON.stringify(data));
        setSelected(id);
        setNewChat(true);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !isLoading) {
      e.preventDefault();
      setShouldAnimate(true);
      HandleQnA(query);
      setIsListening(false);
    }
  };

  useEffect(() => {
    if (isListening) {
      setIsLoading(false);
      setQuery(text);
      inputRef.current.scrollLeft = inputRef.current.scrollWidth;
    }
  }, [isListening, text, setIsLoading, setQuery]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSmallScreen(true);
        dispatch(collapseSidebar());
      } else {
        setIsSmallScreen(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <ShowSelectedChatContainer>
        {isSmallScreen ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "0rem 1rem",
            }}
          >
            <Button onClick={() => setNewChat(false)}>
              <IoCreateOutline
                style={{
                  height: "2.5rem",
                  width: "2.5rem",
                  color: "var(--text-secondary)",
                }}
              />
            </Button>
          </div>
        ) : null}
        <ChatResponseBox ref={containerRef}>
          {chatHistory[indexOfSelected] &&
            chatHistory[indexOfSelected]["messages"].map((item, index) => (
              <ReplyComponent
                query={item.query}
                key={index}
                answer={item.message}
                shouldAnimate={shouldAnimate}
                containerRef={containerRef}
              />
            ))}
        </ChatResponseBox>
        <InputContainer className="">
          <div
            style={{
              position: "relative",
            }}
          >
            <StyledInput
              type="text"
              placeholder="Start conversation..."
              disableUnderline
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              disabled={isLoading}
              forwardedRef={inputRef}
              endAdornment={
                <InputAdornment position="end">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {isListening ? (
                      <div style={{ flex: "0 0 auto", marginRight: "0.8rem" }}>
                        <IconButton
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                          onClick={() => {
                            if (!hasRecognitionSupport) {
                              toast.error(
                                "Your browser is not supported for speech recognition"
                              );
                              return;
                            }
                            stopListening();
                            inputRef.current.blur();
                          }}
                        >
                          <UseAnimations
                            animation={activity}
                            wrapperStyle={{
                              color: "var(--text-secondary)",
                              width: "2.5rem",
                              height: "2.5rem",
                              cursor: "pointer",
                            }}
                            strokeColor="var(--text-secondary)"
                          />
                        </IconButton>
                      </div>
                    ) : (
                      <div style={{ flex: "0 0 auto", marginRight: "0.8rem" }}>
                        <IconButton
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                          onClick={() => {
                            if (!hasRecognitionSupport) {
                              toast.error(
                                "Your browser is not supported for speech recognition"
                              );
                              return;
                            }
                            startListening();
                            inputRef.current.focus();
                          }}
                        >
                          <IoMdMic
                            style={{
                              color: "var(--text-secondary)",
                              width: "2.5rem",
                              height: "2.5rem",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </div>
                    )}
                    {isLoading ? (
                      <div style={{ flex: "0 0 auto", marginRight: "0.8rem" }}>
                        <IconButton
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          <CircularProgress
                            style={{
                              color: "var(--text-secondary)",
                              width: "2.5rem",
                              height: "2.5rem",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </div>
                    ) : (
                      <div style={{ flex: "0 0 auto", marginRight: "0.8rem" }}>
                        <IconButton
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                          onClick={(e) => {
                            setShouldAnimate(true);
                            HandleQnA(query);
                          }}
                        >
                          <IoMdSend
                            style={{
                              color: "var(--text-secondary)",
                              width: "2.5rem",
                              height: "2.5rem",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </InputAdornment>
              }
            />
          </div>
        </InputContainer>
      </ShowSelectedChatContainer>
    </>
  );
}

export default ShowSelectedChat;
