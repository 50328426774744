import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import {
  StyledInput,
  InputContainer,
  StyledButton,
  HeaderButton,
  StyledHeading,
  ErrorButton,
  StyledTableRow,
  StyledTableCell,
  StyledTable,
  StyledTableContainer,
  ManageUsersContainer,
} from "../../../Assets/Styles/Pages/ManageUsers";
import { useState, useEffect, useMemo } from "react";
import { FaSearch } from "react-icons/fa";
import { toast } from "react-toastify";
import { TbSortAscending, TbSortDescending } from "react-icons/tb";
import AlertDialog from "../../Models/AlertDialog";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import api, { setNavigateFunction } from "../../../Utils/API/ApiInterceptor";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../../Utils/API/cryptoUtil";

function ManageUsers({ toggleTheme }) {
  const columns = [
    {
      id: "fname",
      label: "First Name",
      align: "center",
      sortingOrder: null,
    },
    {
      id: "lname",
      label: "Last Name",
      align: "center",
      sortingOrder: null,
    },
    {
      id: "email",
      label: "Email",
      align: "center",
      sortingOrder: null,
    },
  ];

  /**** Verified User variables ****/
  const [verifiedUserData, setVerifiedUserData] = useState([]);
  const [pageOfVerified, setPageOfVerified] = useState(0);
  const [rowsPerPageOfVerified, setRowsPerPageOfVerified] = useState(10);
  const [searchQueryOfVerified, setSearchQueryOfVerified] = useState("");
  const [selectedEmailOfVerified, setSelectedEmailOfVerified] = useState(null);
  const [sortingOfVerified, setSortingOfVerified] = useState({
    fname: "asc",
    lname: "asc",
    email: "asc",
  });
  const [selectedUserIdOfVerified, setSelectedUserIdOfVerified] =
    useState(null);
  const [selectedActionTypeOfVerified, setSelectedActionTypeOfVerified] =
    useState(null);

  /****common variables with both tables ****/
  const [open, setOpen] = useState({
    openOfVerified: false,
    openOfUnverified: false,
  });
  const [loggedInUser, setLoggedInUser] = useState(
    decryptData(sessionStorage.getItem("user"))
  );
  const navigate = useNavigate();
  setNavigateFunction(navigate);

  /****verified user functions****/
  const handleClickOpenOfVerified = () => {
    setOpen({ ...open, openOfVerified: true });
  };

  const handleCloseOfVerified = () => {
    setOpen({ ...open, openOfVerified: false });
  };

  const handleChangePageOfVerified = (event, newPage) => {
    setPageOfVerified(newPage);
  };

  const handleChangeRowsPerPageOfVerified = (event) => {
    setRowsPerPageOfVerified(+event.target.value);
    setPageOfVerified(0);
  };

  const handleSearchChangeOfVerified = (event) => {
    setSearchQueryOfVerified(event.target.value);
    setPageOfVerified(0);
  };

  const handleUserActionConfirmationOfVerified = async (actionType) => {
    if (selectedUserIdOfVerified || selectedEmailOfVerified) {
      try {
        let response;
        if (actionType === "remove") {
          response = await api.post(
            `${process.env.REACT_APP_BASE_URL}/admin/remove_user`,
            {
              user_email: selectedUserIdOfVerified,
            }
          );
        } else if (actionType === "restrict") {
          response = await api.post(
            `${process.env.REACT_APP_BASE_URL}/admin/restrict_user`,
            {
              user_email: selectedUserIdOfVerified,
            }
          );
        } else if (actionType === "unrestrict") {
          response = await api.post(
            `${process.env.REACT_APP_BASE_URL}/admin/unrestrict_user`,
            {
              user_email: selectedUserIdOfVerified,
            }
          );
        } else if (actionType === "changeRole") {
          response = await api.post(
            `${process.env.REACT_APP_BASE_URL}/admin/change_user_role`,
            {
              user_email: selectedEmailOfVerified,
            }
          );
        }
        if (response.status === 200) {
          await fetchUsers();
          toast.success(response?.data?.message);
        }
      } catch (err) {
        toast.error(err?.response?.data?.error);
      }
      setSelectedUserIdOfVerified(null);
      setSelectedEmailOfVerified(null);
      setSelectedActionTypeOfVerified(null);
      handleCloseOfVerified();
    }
  };

  const handleSortingOfVerified = (columnId) => {
    setSortingOfVerified((prevSorting) => ({
      ...prevSorting,
      [columnId]: prevSorting[columnId] === "asc" ? "desc" : "asc",
    }));

    setVerifiedUserData((prevVerifiedUserData) => {
      const sortedData = [...prevVerifiedUserData].sort((a, b) => {
        const aValue = a[columnId];
        const bValue = b[columnId];

        if (aValue !== bValue) {
          const sortOrder = sortingOfVerified[columnId] === "asc" ? 1 : -1;
          return aValue.localeCompare(bValue) * sortOrder;
        }
        return 0;
      });
      return sortedData;
    });
    setPageOfVerified(0);
  };

  /****common function for both tables****/
  const fetchUsers = useMemo(() => {
    return async () => {
      try {
        const response = await api.get(
          `${process.env.REACT_APP_BASE_URL}/admin/get_users`,
          {
            headers: {
              user_email: decryptData(sessionStorage.getItem("user")).email,
            },
          }
        );
        if (response.status === 200) {
          setVerifiedUserData(response?.data?.content);
        }
      } catch (err) {
        toast.error(err?.response?.data?.error);
      }
    };
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <>
      <AlertDialog
        open={open.openOfVerified}
        handleClose={open.openOfVerified && handleCloseOfVerified}
        handleConfirmation={
          open.openOfVerified && handleUserActionConfirmationOfVerified
        }
        actionType={open.openOfVerified && selectedActionTypeOfVerified}
        content='user'
      />
      <ManageUsersContainer>
        <StyledHeading>Users</StyledHeading>
        <InputContainer>
          <div
            style={{
              position: "relative",
            }}
          >
            <FaSearch
              style={{
                color: "var(--text-secondary)",
                position: "absolute",
                top: "50%",
                right: "3%",
                width: "2rem",
                height: "2rem",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            />
            <StyledInput
              placeholder='Search Users'
              value={searchQueryOfVerified}
              onChange={handleSearchChangeOfVerified}
            />
          </div>
        </InputContainer>
        <StyledTableContainer component={Paper}>
          <StyledTable sx={{ marginTop: 2 }} aria-label='user table'>
            <TableHead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 5,
                background: "white",
              }}
            >
              <TableRow>
                {columns.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        padding: "0.5rem",
                        cursor: "pointer",
                      }}
                      align={column.align}
                      onClick={() => handleSortingOfVerified(column.id)}
                    >
                      <HeaderButton>
                        {column.label}
                        {sortingOfVerified[column.id] === "asc" && (
                          <TbSortDescending />
                        )}
                        {sortingOfVerified[column.id] === "desc" && (
                          <TbSortAscending />
                        )}
                      </HeaderButton>
                    </TableCell>
                  );
                })}
                <TableCell align='center'>
                  <HeaderButton disabled>Role</HeaderButton>
                </TableCell>
                <TableCell align='center'>{""}</TableCell>
                <TableCell align='center'>{""}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {verifiedUserData
                .filter((row) => row.email !== loggedInUser.email)
                .filter((row) =>
                  columns.some((column) => {
                    return row[column.id]
                      .toString()
                      .toLowerCase()
                      .includes(searchQueryOfVerified.toLowerCase());
                  })
                )
                .slice(
                  pageOfVerified * rowsPerPageOfVerified,
                  pageOfVerified * rowsPerPageOfVerified + rowsPerPageOfVerified
                )
                .map((row) => {
                  return (
                    <StyledTableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={row.email}
                      admin={row.is_admin}
                    >
                      {columns.map((column) => {
                        let value = row[column.id];
                        return (
                          <StyledTableCell key={column.id} align={column.align}>
                            {value}
                          </StyledTableCell>
                        );
                      })}
                      <StyledTableCell align='center'>
                        <FormGroup style={{ alignItems: "center" }}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={row.is_admin ? true : false}
                                color='default'
                                style={{
                                  color:
                                    toggleTheme === "dark"
                                      ? row.is_admin
                                        ? "white"
                                        : "black"
                                      : row.is_admin
                                      ? "black"
                                      : "white",
                                }}
                                onChange={() => {
                                  setSelectedEmailOfVerified(row.email);
                                  setSelectedActionTypeOfVerified("changeRole");
                                  handleClickOpenOfVerified();
                                }}
                              />
                            }
                            label={row.is_admin ? "Admin" : "User"}
                          />
                        </FormGroup>
                      </StyledTableCell>
                      <StyledTableCell align='center'>
                        <ErrorButton
                          onClick={() => {
                            setSelectedUserIdOfVerified(row.email);
                            setSelectedActionTypeOfVerified("remove");
                            handleClickOpenOfVerified();
                          }}
                        >
                          Remove
                        </ErrorButton>
                      </StyledTableCell>
                      {row.is_restricted ? (
                        <StyledTableCell align='center'>
                          <ErrorButton
                            onClick={() => {
                              setSelectedUserIdOfVerified(row.email);
                              setSelectedActionTypeOfVerified("unrestrict");
                              handleClickOpenOfVerified();
                            }}
                          >
                            Allow
                          </ErrorButton>
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell align='center'>
                          <StyledButton
                            toggleTheme={toggleTheme}
                            onClick={() => {
                              setSelectedUserIdOfVerified(row.email);
                              setSelectedActionTypeOfVerified("restrict");
                              handleClickOpenOfVerified();
                            }}
                          >
                            Restrict
                          </StyledButton>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </StyledTable>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={verifiedUserData.length}
            rowsPerPage={rowsPerPageOfVerified}
            page={pageOfVerified}
            onPageChange={handleChangePageOfVerified}
            onRowsPerPageChange={handleChangeRowsPerPageOfVerified}
          />
        </StyledTableContainer>
      </ManageUsersContainer>
    </>
  );
}

export default ManageUsers;
