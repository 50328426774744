import styled from "styled-components";
import { Button, Input, FormHelperText } from "@mui/material";

export const StyledButtonMS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4461f2;
  padding: 0.6rem 1.1rem;
  color: white;
  font-size: 1.3rem;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  & > img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 1rem;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
`;

export const StyledInput = styled(
  ({ endAdornment, forwardedRef, ...props }) => (
    <Input
      {...props}
      inputRef={forwardedRef}
      sx={{ fontSize: "1.5rem" }}
      endAdornment={endAdornment}
    />
  )
)`
  width: 100%;
  height: 6rem;
  background: var(--highlighting-background);
  outline: none;
  overflow: hidden;
  padding: 2.2rem 3rem;
  border: none;
  border-radius: 0.5rem;
  color: var(--text-primary);
  transition: all 0.2s linear;
  font-size: 1.5rem;
  &:focus {
    color: var(--text-secondary);
    border-bottom: 2px solid grey;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  margin: 0 auto;
  background: var(--highlighting-background) !important;
  color: var(--text-primary) !important;
  font-size: 1.3rem !important;
  text-transform: none !important;
  font-weight: bold !important;
  padding: 1rem 3rem !important;
  & > svg {
    color: var(--text-primary) !important;
    font-size: 1.5rem;
    margin-right: 0.6rem;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: center;
  align-items: center;
  padding: 0 5%;
  gap: 10px;
  @media (max-width: 76.8em) {
    margin-top: 3rem;
  }
`;

export const HeadingDescription = styled.h3`
  text-align: center;
  width: 100%;
  max-width: 90rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 76.8em) {
    text-align: center;
    font-size: 1.5rem;
    width: auto;
    display: flex;
  }
`;

export const WelcomeBackHeading = styled.h1`
  text-align: center;
  width: 100%;
  font-size: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 76.8em) {
    text-align: center;
    font-size: 3rem;
    width: auto;
    display: flex;
    ul {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const FormContainer = styled.div`
  width: 60%;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;

  @media (max-width: 76.8em) {
    width: 100%;
    padding: 3%;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: 150px;
  object-fit: cover;
  @media (max-width: 76.8em) {
    object-fit: contain;
  }
`;

export const StyledText = styled.h3`
  font-size: 1rem;
  color: var(--text-primary);
  font-weight: bold;
  margin-bottom: 0rem;
  margin-top: 2rem;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  color: var(--text-primary) !important;
  font-size: 1rem !important;
  display: flex !important;
  align-items: center !important;
  gap: 0.5rem !important;
`;

export const MainLayout = styled.div`
  width: 100%;
  max-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--background);
  overflow: hidden;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 76.8em) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  flex: 1;
`;
