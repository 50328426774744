import React from "react";
import { Outlet } from "react-router-dom";
import Tabs from "../../ReusableComponents/Tabs";
import {
  AdminSettingContainer,
  RestrictWidth,
} from "../../../Assets/Styles/Pages/AdminSetting";
// import UploadFile from "../../ReusableComponents/UploadFile";

function AdminSetting() {
  const TabValue = [
    {
      heading: "Files",
      url: "/setting/files",
    },

    {
      heading: "Users",
      url: "/setting/users",
    },
    {
      heading: "Queries",
      url: "/setting/queries",
    },
    // {
    //   heading: "Ratings",
    //   url: "/setting/ratings",
    // },
  ];

  return (
    <AdminSettingContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tabs value={TabValue} />
      </div>

      <RestrictWidth>
        <Outlet />
      </RestrictWidth>
    </AdminSettingContainer>
  );
}

export default AdminSetting;
