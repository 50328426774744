import styled from "styled-components";
import { Button } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

export const StyledHeading = styled.h2`
  font-size: 2rem;
  color: var(--text-primary);
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 3rem;
  position: relative;
  top: 0;
  left: 0;
  min-width: calc(100vw - 40rem);
`;

export const QueriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledInput = styled.input`
  min-width: calc(100vw - 40rem);
  height: 2rem;
  background: var(--highlighting-background);
  outline: none;
  overflow: hidden;
  padding: 2.2rem 3rem;
  border: none;
  border-radius: 0.5rem;
  color: var(--text-primary);
  transition: all 0.2s linear;
  &:focus {
    color: var(--text-secondary);
    border-bottom: 0.2rem solid grey;
  }
`;

export const InputContainer = styled.div`
  flex: 1;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  color: var(--text-secondary) !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  gap: 0rem;
  background: var(--highlighting-background) !important;
  padding: 0.65rem 2rem !important;
  font-size: 1rem !important;
  font-weight: bold;
  text-transform: none !important;
`;

export const HeaderButton = styled(Button)`
  color: var(--text-secondary) !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  gap: 0rem;
  background: var(--highlighting-background) !important;
  padding: 0.65rem 2rem !important;
  font-size: 1.3rem !important;
  font-weight: bold;
  text-transform: none !important;
  border-radius: 0.5rem !important;
  border: 0.1rem solid var(--background) !important;
  white-space: nowrap;
`;

export const StyledTableContainer = styled(TableContainer)`
  max-height: calc(100vh - 40rem);
  min-width: calc(100vw - 40rem);
  overflow-y: auto;
  overflow-x: auto;
`;

export const StyledTableRow = styled(TableRow)`
  background-color: var(--highlighting-background) !important;
  color: var(--text-primary) !important;
`;

export const StyledTableCell = styled(TableCell)`
  color: var(--text-primary) !important;
  font-size: 1.1rem !important;
  padding: 0.25rem 0.5rem !important;
  display: flex;
  justify-content: center;
`;

export const ErrorButton = styled(Button)`
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  gap: 0rem;
  background: var(--error-bg) !important;
  padding: 0.65rem 2rem !important;
  font-size: 1rem !important;
  font-weight: bold;
  text-transform: none !important;
  // border-radius: 5px !important;
  // border: 1px solid var(--background) !important;
`;

export const StyledTable = styled.table`
  min-width: calc(100vw - 40rem);
  box-sizing: border-box;
`;
