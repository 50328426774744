import styled, { keyframes } from "styled-components";
import { Button } from "@mui/material";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ToggleThemeButton = styled(Button)`
  color: ${({ active }) => {
    return active
      ? "var(--text-secondary) !important"
      : "var(--text-primary) !important";
  }};

  border: ${({ active }) => {
    return active
      ? "2px solid var(--highlighting-background) !important"
      : "0px solid var(--text-primary) !important";
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  padding: 0.3rem 1.2rem !important;
  animation: ${fadeIn} 0.3s ease;
`;

export const SwitchContainer = styled.div`
  background: var(--highlighting-background);
  padding: ${({ expand }) => {
    return expand ? "1rem" : "0rem";
  }};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.7rem;
  margin-top: auto;
  transition: padding 0.3s ease;
`;

export const ItemTitle = styled.p`
  font-size: ${({ expand }) => (expand ? "1.3rem" : "0rem")};
  width: ${({ expand }) => (expand ? "17rem" : "0rem")};
  height: ${({ expand }) => (expand ? "auto" : "0rem")};
  margin-left: ${({ expand }) => (expand ? "1.5rem" : "0rem")};
  overflow: hidden;
  opacity: ${({ expand }) => (expand ? "1" : "0")};
  color: ${({ isActive, expand }) =>
    !expand
      ? "transparent"
      : isActive
      ? "var(--text-secondary)"
      : "var(--text-primary)"};
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.0175rem;
  transition: width 0.3s ease, height 0.3s ease, margin-left 0.3s ease,
    color 0.3s ease, opacity 0.3s ease, font-size 0.5s ease;
`;

export const SidebarContainer = styled.div`
  padding: 1rem;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  border: 0.2rem solid var(--highlighting-background);
  transition: padding 0.3s ease, border-radius 0.3s ease, border 0.3s ease;
`;

export const SideBarItem = styled.div`
  display: ${({ expand, chatFlag }) => (!expand && chatFlag ? "none" : "flex")};
  padding: ${({ expand }) => {
    return expand ? "0rem 1.8rem" : "0rem 1rem";
  }};
  height: 4.5rem;
  border-radius: 0.7rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 1rem;
  transition: padding 0.3s ease, background 0.3s ease, color 0.3s ease;
  background: ${({ isActive }) => {
    return isActive ? "var(--highlighting-background)" : "inherit";
  }};

  &:hover {
    background: var(--highlighting-background);
  }

  &:hover > svg,
  &:hover > p {
    color: var(--text-secondary);
  }

  & > svg {
    color: ${({ isActive }) => {
      return isActive ? "var(--text-secondary)" : "var(--text-primary)";
    }};
    font-size: 1.8rem;
    align-items: center;
    justify-content: center;
  }
`;

export const ChatHistoryButton = styled(Button)`
  width: 100%;
  color: var(--text-secondary) !important;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  background: var(--highlighting-background) !important;
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
  font-size: 1.2rem !important;
  font-weight: bold;
  text-transform: none !important;
  transition: color 0.3s ease, background 0.3s ease, padding-top 0.3s ease,
    padding-bottom 0.3s ease, font-size 0.3s ease, font-weight 0.3s ease;
`;

export const Image = styled.img`
  width: 2.5rem;
  filter: ${(props) =>
    props.theme === "light" ? "invert(0%)" : "invert(80%)"};
  transition: filter 0.3s ease;
`;

export const StyledBtn = styled(Button)`
  width: 100%;
  color: var(--text-secondary) !important;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  gap: 0.5rem;
  padding: 0.65rem 1rem !important;
  font-size: 1rem !important;
  font-weight: bold;
  text-transform: none !important;
  border-radius: 0.5rem !important;
`;

export const PopperDiv = styled(({ forwardedRef, ...props }) => (
  <div {...props} ref={forwardedRef} />
))`
  margin: 0.5rem 0rem;
  z-index: 999999;
  height: 8rem;
  width: 10rem;
  border-radius: 1rem;
  padding: 0.5rem;
  background: var(--background-shade-1);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;
