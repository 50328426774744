import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Image,
  WelcomeBackHeading,
  MainLayout,
  HeadingContainer,
  StyledButtonMS,
  HeadingDescription,
  ButtonContainer,
} from "../../Assets/Styles/Pages/Login";
import { toast } from "react-toastify";
import { decryptData, encryptData } from "../../Utils/API/cryptoUtil";
import dayjs from "dayjs";
import { useCookies } from "react-cookie";

const LogoImage = () => (
  <Image alt='logo' src={process.env.PUBLIC_URL + "/logo.png"} />
);

function Login() {
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies();

  const fetchChatHistory = async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/chat_history`,
        {
          headers: {
            token: token,
            user_email: decryptData(sessionStorage.getItem("user")).email,
          },
          withCredentials: true,
        }
      );
      return response?.data?.content;
    } catch (err) {
      throw err.response?.data?.error;
    }
  };

  const processChatHistory = (responseData) => {
    const data = [];

    responseData.forEach((session) => {
      const id = session.sessionId;
      const messages = session.messages;
      const heading = session.heading;
      const timestamp = dayjs(session.timestamp).format("YYYYMMDDHHmmss");

      const processedMessages = [];
      for (let i = 0; i < messages.length; i += 2) {
        const queryMessage =
          messages[i].type === "human" ? messages[i].content : "";
        const aiMessage =
          i + 1 < messages.length && messages[i + 1].type === "ai"
            ? messages[i + 1].content
            : "";

        const obj = {
          query: queryMessage,
          message: aiMessage,
        };

        processedMessages.push(obj);
      }

      data.push({
        messages: processedMessages,
        heading: heading,
        id: id,
        timestamp: timestamp,
      });
    });

    return data;
  };

  const LoginHandler = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/user/login/`
      );

      if (res.status === 200) {
        window.location.href = res.data.auth_url;
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  const cookiesSent = ["user", "token"];

  useEffect(() => {
    const cookieKeys = Object.keys(cookies);

    if (
      cookieKeys.includes("lname") &&
      cookieKeys.includes("fname") &&
      cookieKeys.includes("email") &&
      cookieKeys.includes("token")
    ) {
      (async () => {
        for (const val of cookiesSent) {
          switch (val) {
            case "user":
              let user = {};

              cookieKeys.forEach((key) => {
                if (key !== "token") {
                  user[key] = cookies[key];
                }
              });

              const encryptedData = encryptData(user);
              sessionStorage.setItem("user", encryptedData);
              break;

            case "token":
              sessionStorage.setItem("token", cookies[val]);
              const chatHistory = await fetchChatHistory(cookies["token"]);
              const processedChatHistory = processChatHistory(chatHistory);
              sessionStorage.setItem(
                "chat_history",
                JSON.stringify(processedChatHistory)
              );

              break;

            default:
              break;
          }
        }

        Object.keys(cookies).forEach((val) => {
          removeCookie(val, {
            path: "/",
          });
        });

        const { fname, lname } = cookies;

        if (sessionStorage.getItem("token")) {
          navigate("/create-chat", {
            state: {
              message: `Welcome back, ${fname} ${lname}! You have successfully logged in`,
            },
          });
        }
      })();
    }
  }, []);

  return (
    <>
      <MainLayout>
        <HeadingContainer>
          <WelcomeBackHeading>
            <LogoImage />
          </WelcomeBackHeading>
          <WelcomeBackHeading>AlHexa ChatBot</WelcomeBackHeading>
          <HeadingDescription>
            Welcome! Our AI Chatbot is dedicated to providing you with
            comprehensive and up-to-date information on our company policies,
            ensuring you have all the details you need for a clear
            understanding.
          </HeadingDescription>
        </HeadingContainer>
        <ButtonContainer>
          <StyledButtonMS onClick={LoginHandler}>
            <img
              src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/1024px-Microsoft_logo.svg.png'
              alt='microsoft'
            />
            Login with Microsoft
          </StyledButtonMS>
        </ButtonContainer>
      </MainLayout>
    </>
  );
}

export default Login;
