import styled from "styled-components";

export const MainLayout = styled.div`
  width: 100%;
  max-height: 100vh;
  height: 100%;
  display: flex;
  background: var(--background);
  overflow: hidden;

  @media (max-width: 76.8em) {
    display: block;
  }
`;

export const LeftContainer = styled(({ forwardedRef, ...props }) => (
  <div {...props} ref={forwardedRef} />
))`
  height: 100%;

  @media (max-width: 76.8em) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 2;
    background-color: var(--background);
  }
`;

export const RightContainer = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  position: relative;
  margin-left: 5.5rem;

  @media (max-width: 76.8em) {
    padding-left: 1.1rem;
    z-index: 0;
  }

  @media (min-width: 76.8em) {
    margin-left: 0rem;
  }
`;
