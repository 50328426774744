import styled from "styled-components";
import { Alert, Input } from "@mui/material";

export const GridBox = styled.div`
  display: grid;
  max-width: 100rem;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  gap: 1rem;
  justify-items: center;
  margin: 0 auto;
  padding: 2rem;
`;

export const ContentItem = styled.div`
  cursor: pointer;
  background: var(--background-shade-1);
  width: 100%;
  height: 16rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;

  & > span {
    display: inline-block;
    & > svg {
      width: 4rem;
      height: 4rem;
      color: var(--text-secondary);
    }
  }

  & p {
    font-size: 1.6rem;
    margin-top: 1rem;
    color: var(--text-secondary);
    font-weight: 600;
    letter-spacing: 0.01rem;
  }

  @media (max-width: 76.8em) {
    height: 12rem;
    & > span {
      & > svg {
        width: 3rem;
        height: 3rem;
      }
    }

    & p {
      font-size: 1.4rem;
    }
  }
`;

export const StyledHeading = styled.h1`
  font-size: 2.5rem;
  color: var(--text-secondary);
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 76.8em) {
    font-size: 2rem;
  }
`;

export const StyledSubHeading = styled.h1`
  font-size: 1.8rem;
  color: var(--text-secondary);
  text-align: center;

  @media (max-width: 76.8em) {
    font-size: 1.5rem;
  }
`;

export const NewChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--right-bg);
  padding: 2rem 0rem;
`;

export const ContentBox = styled.div`
  flex-basis: 85%;
  overflow-y: auto;
`;

export const InputContainer = styled.div`
  flex: 1;
  padding: 1rem 2rem;
  align-items: center;
  justify-content: center;

  @media (max-width: 76.8em) {
    padding: 2rem;
  }
`;

export const StyledInput = styled(
  ({ endAdornment, forwardedRef, ...props }) => (
    <Input
      {...props}
      endAdornment={endAdornment}
      inputRef={forwardedRef}
      sx={{
        fontSize: "1.3rem",
        color: "var(--text-primary)",
        width: "100%",
        borderRadius: "0.5rem",
        outline: "none",
        overflow: "hidden",
        padding: "2.2rem 2rem",
        border: "none",
        height: "4rem",
        background: "var(--highlighting-background)",
        transition: "all 0.2s linear",
      }}
    />
  )
)``;

export const StyledAlert = styled(Alert)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    color: var(--text-secondary);
    background: var(--highlighting-background);
    font-weight: 100;
    width: 96rem;
  }

  @media (max-width: 76.8em) {
    && {
      font-size: 1.2rem;
    }
    .MuiAlert-icon {
      display: flex;
      align-items: center;
    }
  }
`;
