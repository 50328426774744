import React, { useState, useEffect } from "react";
import { IoHomeOutline, IoSaveOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { IoSettingsOutline } from "react-icons/io5";
import { RiDeleteBin7Line, RiProfileLine } from "react-icons/ri";
import { CiLight } from "react-icons/ci";
import { BsMoon } from "react-icons/bs";
import { IoLogOutOutline } from "react-icons/io5";
import { VscFeedback } from "react-icons/vsc";
import { FaUserAlt } from "react-icons/fa";
import AnimateText from "../Models/AnimateText";
import { decryptData } from "../../Utils/API/cryptoUtil";
import { toast } from "react-toastify";
import {
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
} from "@mui/material";
import { expandSidebar, collapseSidebar } from "../../Redux/expandSidebarSlice";
import { useSelector, useDispatch } from "react-redux";
import { BiMessageDots } from "react-icons/bi";
import { FaHistory } from "react-icons/fa";
import {
  SidebarContainer,
  SideBarItem,
  ItemTitle,
  SwitchContainer,
  ToggleThemeButton,
  Image,
  StyledBtn,
  PopperDiv,
} from "../../Assets/Styles/Pages/Sidebar";
import api from "../../Utils/API/ApiInterceptor";
import { deleteChat } from "../../Redux/chatHistorySlice";
import { usePopper } from "react-popper";
import { MdModeEdit } from "react-icons/md";
import { LuMoreHorizontal } from "react-icons/lu";
import { ImCancelCircle } from "react-icons/im";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { useCookies } from "react-cookie";

const Data = [
  {
    title: "Create Chat",
    order: 1,
    logo: <IoHomeOutline />,
    access: "both",
    to: "create-chat",
  },
  {
    title: "Profile",
    order: 1,
    logo: <RiProfileLine />,
    access: "both",
    to: "profile",
  },
  {
    title: "Contact Us",
    order: 1,
    logo: <VscFeedback />,
    access: "user",
    to: "contact",
  },
  {
    title: "Admin Settings",
    order: 1,
    logo: <IoSettingsOutline />,
    access: "admin",
    to: "setting",
  },
];

const ChatHistoryItems = ({
  value,
  setNewChat,
  setSelected,
  setShouldAnimate,
  expand,
  chatFlag,
  handleChatDelete,
}) => {
  const chatHistory = JSON.parse(sessionStorage.getItem("chat_history")) || [];
  const indexOfValue = chatHistory.findIndex((item) => item.id === value);
  const [heading, setHeading] = useState(chatHistory[indexOfValue].heading);
  const [popperVisible, setPopperVisible] = useState();
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  const handleHeadingChange = async () => {
    if (!heading) {
      toast.error("Heading cannot be empty");
      return;
    }
    try {
      setIsLoading(true);
      const response = await api.post("/user/edit_chat_heading", {
        sessionId: value,
        chatHeading: heading,
        user_email: decryptData(sessionStorage.getItem("user")).email,
      });
      if (response.status === 200) {
        chatHistory[indexOfValue].heading = heading;
        sessionStorage.setItem("chat_history", JSON.stringify(chatHistory));
        setIsEdit(false);
        toast.success(response?.data?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperElement && !popperElement.contains(event.target)) {
        setPopperVisible(false);
        setIsEdit(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popperElement]);

  useEffect(() => {
    if (!expand) {
      setPopperVisible(false);
      setIsEdit(false);
    }
  }, [expand]);

  if (chatHistory[indexOfValue]?.["messages"]?.length > 0) {
    const queryText = chatHistory[indexOfValue].heading;
    return (
      <>
        <SideBarItem
          expand={expand}
          chatFlag={chatFlag}
          onClick={() => {
            setSelected(value);
            setNewChat(true);
            setShouldAnimate(false);
          }}
          ref={setReferenceElement}
        >
          <BiMessageDots />
          {isEdit ? (
            <>
              <ItemTitle expand={expand}>
                <Input
                  style={{
                    marginLeft: "0.5rem",
                    color: "var(--text-secondary)",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                  value={heading}
                  onChange={(e) => setHeading(e.target.value)}
                  disableUnderline
                  endAdornment={
                    <InputAdornment position="end">
                      {isLoading ? (
                        <div
                          style={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <IconButton>
                            <CircularProgress
                              style={{
                                color: "var(--text-secondary)",
                                width: "2rem",
                                height: "2rem",
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                        </div>
                      ) : (
                        <>
                          <IconButton onClick={handleHeadingChange}>
                            <IoSaveOutline
                              style={{
                                color: "var(--text-secondary)",
                                width: "1.5rem",
                                height: "1.5rem",
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>

                          <IconButton onClick={(e) => setIsEdit(false)}>
                            <ImCancelCircle
                              style={{
                                color: "var(--text-secondary)",
                                width: "1.5rem",
                                height: "1.5rem",
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                        </>
                      )}
                    </InputAdornment>
                  }
                />
              </ItemTitle>
            </>
          ) : (
            <ItemTitle expand={expand}>
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "2.5rem",
                  }}
                >
                  {queryText?.length < 20
                    ? queryText
                    : queryText?.substr(0, 15) + "..."}
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "1rem",
                    }}
                  >
                    <LuMoreHorizontal
                      style={{ fontSize: "1.5rem", cursor: "pointer" }}
                      onClick={(e) => setPopperVisible(!popperVisible)}
                    />
                  </div>
                </div>
              </>
            </ItemTitle>
          )}
          {popperVisible && (
            <PopperDiv
              style={{
                ...styles.popper,
                ...attributes.popper,
              }}
              forwardedRef={setPopperElement}
            >
              <StyledBtn onClick={(e) => setIsEdit(true)}>
                <MdModeEdit
                  style={{
                    color: "var(--text-secondary)",
                    fontSize: "1.5rem",
                  }}
                />
                <p>Edit</p>
              </StyledBtn>
              <StyledBtn onClick={() => handleChatDelete(value, indexOfValue)}>
                <RiDeleteBin7Line
                  style={{
                    color: "var(--text-secondary)",
                    fontSize: "1.5rem",
                  }}
                />
                <p>Delete</p>
              </StyledBtn>
            </PopperDiv>
          )}
        </SideBarItem>
      </>
    );
  } else {
    return null;
  }
};

function Sidebar({
  setToggleTheme,
  toggleTheme,
  setNewChat,
  setSelected,
  setShouldAnimate,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [theme, setTheme] = useState("light");
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const user = decryptData(sessionStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(false);
  const expand = useSelector((state) => state.sidebar.sidebarState);
  const chatHistory = useSelector((state) => state.chatHistory);
  const [chatFlag, setChatFlag] = useState(true);
  const dispatch = useDispatch();
  const params = useParams()["*"];
  const [hardRefresh, setHardRefresh] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();

  const HandleExpandable = () => {
    if (expand) {
      dispatch(collapseSidebar());
    } else {
      dispatch(expandSidebar());
    }
  };

  const handleToggleTheme = (selectedTheme) => {
    setTheme(selectedTheme);
    setToggleTheme(selectedTheme);
  };

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      const response = await api.post(
        `${process.env.REACT_APP_BASE_URL}/user/logout`,
        {
          user_email: decryptData(sessionStorage.getItem("user")).email,
        }
      );
      setIsLoading(false);
      if (response.status === 200) {
        Object.keys(cookies).forEach((val) => {
          removeCookie(val, {
            path: "/",
          });
        });

        sessionStorage.clear();
        navigate("/login", { state: { message: response.data.message } });
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.error);
    }
  };

  const handleChatDelete = async (value, indexOfValue) => {
    try {
      const response = await api.post("/user/delete_single_chat", {
        sessionId: value,
        user_email: decryptData(sessionStorage.getItem("user")).email,
      });
      if (response.status === 200) {
        setHardRefresh((prev) => !prev);
        dispatch(deleteChat(value));
        const updatedChatHistory =
          JSON.parse(sessionStorage.getItem("chat_history")) || [];
        updatedChatHistory.splice(indexOfValue, 1);
        sessionStorage.setItem(
          "chat_history",
          JSON.stringify(updatedChatHistory)
        );
        setNewChat(false);
        toast.success(response?.data?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.error);
    } finally {
      dispatch(collapseSidebar());
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSmallScreen(true);
        dispatch(collapseSidebar());
      } else {
        setIsSmallScreen(false);
        dispatch(expandSidebar());
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <SidebarContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <SideBarItem
            style={{
              marginBottom: "1rem",
            }}
            expand={expand}
            onClick={HandleExpandable}
          >
            {expand ? <FaChevronLeft /> : <FaChevronRight />}

            <ItemTitle
              style={{
                color: "var(--text-secondary)",
              }}
              expand={expand}
            >
              Close Sidebar
            </ItemTitle>
          </SideBarItem>
          <SideBarItem
            style={{
              marginBottom: "2rem",
              backgroundColor: "transparent",
            }}
            expand={expand}
          >
            <Image
              alt="logo"
              src={process.env.PUBLIC_URL + "/logo.png"}
              theme={theme}
            />

            <ItemTitle
              style={{
                fontWeight: "bold",
                color: "var(--text-secondary)",
              }}
              expand={expand}
            >
              AlHexa ChatBot
            </ItemTitle>
          </SideBarItem>

          <SideBarItem
            style={{
              marginBottom: "1rem",
              backgroundColor: "transparent",
            }}
            expand={expand}
          >
            <FaUserAlt />
            <ItemTitle
              style={{
                fontWeight: "bold",
                color: "var(--text-secondary)",
              }}
              expand={expand}
            >
              <AnimateText
                text={`Hello, ${user.fname} ${user.lname}`}
                speed={50}
                expand={expand}
              />
            </ItemTitle>
          </SideBarItem>
        </div>

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <ul>
            {Data.map((data) => {
              const shouldRenderItem =
                data.access === "both" ||
                (data.access === "user" && user.is_admin === "False") ||
                (data.access === "admin" && user.is_admin === "True");

              if (shouldRenderItem) {
                return (
                  <li key={data.title}>
                    <Link to={data.to}>
                      <SideBarItem
                        onClick={() =>
                          data.title === "Create Chat"
                            ? setNewChat(false)
                            : setNewChat(true)
                        }
                        expand={expand}
                        isActive={
                          location.pathname === `/${data.to}` ||
                          location.pathname.startsWith(`/${data.to}`)
                        }
                      >
                        {data.logo}
                        <ItemTitle
                          expand={expand}
                          isActive={
                            location.pathname === `/${data.to}` ||
                            location.pathname.startsWith(`/${data.to}`)
                          }
                        >
                          {data.title}
                        </ItemTitle>
                      </SideBarItem>
                    </Link>
                  </li>
                );
              }
              return null;
            })}

            <li>
              <SideBarItem expand={expand} onClick={handleLogout}>
                {isLoading ? (
                  <CircularProgress
                    style={{
                      color: "var(--text-secondary)",
                      width: "2rem",
                      height: "2rem",
                    }}
                  />
                ) : (
                  <IoLogOutOutline />
                )}
                <ItemTitle expand={expand}>Logout</ItemTitle>
              </SideBarItem>
            </li>
          </ul>

          <div
            style={{
              margin: "3rem 0rem",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {isSmallScreen && params === "create-chat" ? (
              <ul>
                <li>
                  <SideBarItem expand={expand} chatFlag={chatFlag}>
                    <FaHistory />
                    <ItemTitle
                      expand={expand}
                      style={{
                        fontWeight: "bold",
                        color: "var(--text-secondary)",
                      }}
                    >
                      Previous Chats
                    </ItemTitle>
                  </SideBarItem>
                </li>
                {chatHistory?.map((value) => (
                  <li key={value}>
                    <ChatHistoryItems
                      setNewChat={setNewChat}
                      setSelected={setSelected}
                      setShouldAnimate={setShouldAnimate}
                      value={value}
                      chatFlag={chatFlag}
                      expand={expand}
                      key={value}
                      handleChatDelete={handleChatDelete}
                    />
                  </li>
                ))}
              </ul>
            ) : null}
          </div>

          <SwitchContainer expand={expand}>
            {expand && (
              <div
                style={{
                  background: "var(--background)",
                  padding: "0.3rem",
                  borderRadius: "0.7rem",
                  overflow: "hidden",
                }}
              >
                <ToggleThemeButton
                  onClick={() => handleToggleTheme("light")}
                  active={theme === "light"}
                >
                  <CiLight
                    style={{
                      color: "var(--text-secondary)",
                      fontSize: "1.4rem",
                    }}
                  />
                  Light
                </ToggleThemeButton>

                <ToggleThemeButton
                  onClick={() => handleToggleTheme("dark")}
                  active={theme === "dark"}
                >
                  <BsMoon
                    style={{
                      color: "var(--text-secondary)",
                      fontSize: "1.1rem",
                    }}
                  />
                  Dark
                </ToggleThemeButton>
              </div>
            )}
          </SwitchContainer>
        </div>
      </SidebarContainer>
    </>
  );
}

export default Sidebar;
