import React, { useEffect, useRef, useState } from "react";
import { FaTaxi } from "react-icons/fa";
import { IoMdSend } from "react-icons/io";
import { IoTime } from "react-icons/io5";
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia";
import { MdFreeBreakfast } from "react-icons/md";
import { RiBodyScanFill } from "react-icons/ri";
import { BiSolidBuildingHouse } from "react-icons/bi";
import {
  StyledInput,
  StyledHeading,
  NewChatContainer,
  ContentBox,
  InputContainer,
  ContentItem,
  GridBox,
  StyledAlert,
  StyledSubHeading,
} from "../../Assets/Styles/Pages/MakeNewChat";
import { CircularProgress, InputAdornment, IconButton } from "@mui/material";
import { toast } from "react-toastify";
import api, { setNavigateFunction } from "../../Utils/API/ApiInterceptor";
import { useNavigate } from "react-router-dom";
import { IoMdMic } from "react-icons/io";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import getCurrentTimestamp from "../../Utils/TimeStampUtil";
import { decryptData } from "../../Utils/API/cryptoUtil";

const ShowContentData = [
  {
    id: 1,
    title: "Cab Policy",
    logo: FaTaxi,
  },
  {
    id: 2,
    title: "Office Hours Inquiry",
    logo: IoTime,
  },
  {
    id: 3,
    title: "Cab Reimbursement Amount",
    logo: LiaMoneyBillWaveAltSolid,
  },
  {
    id: 4,
    title: "Lunch Break Timings",
    logo: MdFreeBreakfast,
  },
  {
    id: 5,
    title: `Founder of ${process.env.REACT_APP_COMPANY_NAME}`,
    logo: RiBodyScanFill,
  },
  {
    id: 6,
    title: `What is ${process.env.REACT_APP_COMPANY_NAME}?`,
    logo: BiSolidBuildingHouse,
  },
];

function MakeNewChat({
  setSelected,
  setNewChat,
  setShouldAnimate,
  query,
  setQuery,
  isLoading,
  setIsLoading,
  text,
  isListening,
  startListening,
  stopListening,
  setIsListening,
  hasRecognitionSupport,
  currentTimeStamp,
}) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();
  setNavigateFunction(navigate);
  const inputRef = useRef(null);

  const HandleQnA = async (queryValue) => {
    if (queryValue.trim().length === 0)
      return toast.error("Please enter a query");

    try {
      setIsLoading(true);

      const response = await api.post(
        `${process.env.REACT_APP_BASE_URL}/user/chat`,
        {
          data: queryValue,
          user_email: decryptData(sessionStorage.getItem("user")).email,
        },
        {
          headers: {
            sessionId: currentTimeStamp,
          },
        }
      );

      if (response.status === 200) {
        setQuery("");
        setIsLoading(false);
        const data = JSON.parse(sessionStorage.getItem("chat_history")) || [];
        const indexOfThread = data.findIndex((item) =>
          item.hasOwnProperty(currentTimeStamp)
        );
        const chatEntry = {
          query: response?.data?.query,
          message: response?.data?.message,
        };
        if (indexOfThread === -1) {
          data.unshift({
            messages: [chatEntry],
            heading: response?.data?.query,
            id: currentTimeStamp,
            timestamp: currentTimeStamp,
          });
        } else {
          data[indexOfThread]["messages"].push(chatEntry);
          data[indexOfThread].timestamp = getCurrentTimestamp();
        }
        data.sort((a, b) => b.timestamp - a.timestamp);
        sessionStorage.setItem("chat_history", JSON.stringify(data));
        setSelected(currentTimeStamp);
        setNewChat(true);
      }
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !isLoading) {
      e.preventDefault();
      setShouldAnimate(true);
      HandleQnA(query);
      setIsListening(false);
    }
  };

  useEffect(() => {
    if (isListening) {
      setIsLoading(false);
      setQuery(text);
      inputRef.current.scrollLeft = inputRef.current.scrollWidth;
    }
  }, [isListening, text, setIsLoading, setQuery]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <NewChatContainer>
        <ContentBox className="content">
          <StyledHeading>
            Welcome to {process.env.REACT_APP_COMPANY_NAME} Chatbot: Your
            Personal Chatbot
          </StyledHeading>
          <StyledSubHeading>Quick Access Queries</StyledSubHeading>
          <GridBox>
            {ShowContentData.map((content, i) => {
              return (
                <ContentItem
                  key={i + 1}
                  onClick={(e) => {
                    if (!isLoading) {
                      setQuery(content.title);
                      setShouldAnimate(true);
                      HandleQnA(content.title);
                    }
                  }}
                >
                  <span>
                    <content.logo />
                  </span>
                  <p style={{ textAlign: "center" }}>{content.title}</p>
                </ContentItem>
              );
            })}
          </GridBox>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <StyledAlert severity="info">
              Respectful communication fosters a positive experience, refrain
              from using violent or abusive language towards the chatbot.
            </StyledAlert>
          </div>
        </ContentBox>
        <InputContainer className="">
          <div
            style={{
              position: "relative",
            }}
          >
            <StyledInput
              type="text"
              placeholder="Start conversation..."
              disableUnderline
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              disabled={isLoading}
              forwardedRef={inputRef}
              endAdornment={
                <InputAdornment position="end">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {isListening ? (
                      <div style={{ flex: "0 0 auto", marginRight: "0.8rem" }}>
                        <IconButton
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                          onClick={() => {
                            if (!hasRecognitionSupport) {
                              toast.error(
                                "Your browser is not supported for speech recognition"
                              );
                              return;
                            }
                            stopListening();
                            inputRef.current.blur();
                          }}
                        >
                          <UseAnimations
                            animation={activity}
                            wrapperStyle={{
                              color: "var(--text-secondary)",
                              width: "2.5rem",
                              height: "2.5rem",
                              cursor: "pointer",
                            }}
                            strokeColor="var(--text-secondary)"
                          />
                        </IconButton>
                      </div>
                    ) : (
                      <div style={{ flex: "0 0 auto", marginRight: "0.8rem" }}>
                        <IconButton
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                          onClick={() => {
                            if (!hasRecognitionSupport) {
                              toast.error(
                                "Your browser is not supported for speech recognition"
                              );
                              return;
                            }
                            startListening();
                            inputRef.current.focus();
                          }}
                        >
                          <IoMdMic
                            style={{
                              color: "var(--text-secondary)",
                              width: "2.5rem",
                              height: "2.5rem",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </div>
                    )}
                    {isLoading ? (
                      <div style={{ flex: "0 0 auto", marginRight: "0.8rem" }}>
                        <IconButton
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                        >
                          <CircularProgress
                            style={{
                              color: "var(--text-secondary)",
                              width: "2.5rem",
                              height: "2.5rem",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </div>
                    ) : (
                      <div style={{ flex: "0 0 auto", marginRight: "0.8" }}>
                        <IconButton
                          onMouseDown={(event) => event.preventDefault()}
                          edge="end"
                          onClick={(e) => {
                            setShouldAnimate(true);
                            HandleQnA(query);
                          }}
                        >
                          <IoMdSend
                            style={{
                              color: "var(--text-secondary)",
                              width: "2.5rem",
                              height: "2.5rem",
                              cursor: "pointer",
                            }}
                          />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </InputAdornment>
              }
            />
          </div>
        </InputContainer>
      </NewChatContainer>
    </>
  );
}

export default MakeNewChat;
