import styled from "styled-components";
import { Button, Typography } from "@mui/material";
import { TextareaAutosize } from "@mui/material";

export const ContactUsContainer = styled.div`
  padding: 5rem 3rem 3rem 3rem;
`;

export const RestrictWidth = styled.div`
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 4rem;
  background: var(--highlighting-background);
  outline: none;
  overflow: hidden;
  padding: 2.2rem 3rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1.9rem !important;
  color: var(--text-primary);
  transition: all 0.2s linear;
  margin-bottom: 0.5rem;
  &::-webkit-input-placeholder {
    color: var(--text-secondary);
  }
`;

export const StyledTextField = styled(TextareaAutosize)`
  width: 100%;
  height: 4rem;
  background: var(--highlighting-background);
  outline: none;
  overflow: hidden;
  padding: 2.2rem 3rem;
  border: none;
  font-size: 1.8rem !important;
  border-radius: 0.5rem;
  color: var(--text-primary);
  transition: all 0.2s linear;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif;
  &::-webkit-input-placeholder {
    color: var(--text-secondary);
  }
`;

export const StyledButton = styled(Button)`
  color: var(--text-secondary) !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  gap: 0rem;
  background: var(--highlighting-background) !important;
  padding: 0.65rem 2rem !important;
  font-size: 1.7rem !important;
  font-weight: bold;
  text-transform: none !important;
  border-radius: 0.5rem !important;
  border: 0.1rem solid var(--highlighting-background) !important;
  margin: 3rem 0rem !important;
`;
export const StyledHeading = styled.h2`
  font-size: 2.7rem;
  color: var(--text-primary);
  font-weight: bold;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;
export const InputLabel = styled.label`
  font-size: 1.6rem;
  font-weight: bold;
  color: var(--text-primary);
  margin-right: 1rem;
  width: 30%;
  display: inline-block;

  @media (max-width: 76.8em) {
    width: 100%;
  }
`;
export const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
`;
export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledTypography = styled(Typography)`
  font-size: 1.8rem !important;
  color: var(--text-primary);
  font-family: "Poppins", sans-serif;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 76.8em) {
    justify-content: flex-start;
  }
`;
