import styled from "styled-components";

export const AdminSettingContainer = styled.div`
  padding: 5rem 3rem 3rem 3rem;
`;

export const RestrictWidth = styled.div`
  max-width: 90rem;
  width: 100%;
  margin: 0 auto;
`;
