import { useState } from "react";
import Button from "@mui/material/Button";
import { Input } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FiUpload } from "react-icons/fi";
import styled from "styled-components";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import api, { setNavigateFunction } from "../../Utils/API/ApiInterceptor";
import { useNavigate } from "react-router-dom";

export default function FileUpload({ fetchPdfData }) {
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  setNavigateFunction(navigate);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      toast.info("Please select a file before uploading.");
      return;
    }
    if (selectedFile.type === "application/pdf") {
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        setIsLoading(true);
        const response = await api.post(
          `${process.env.REACT_APP_BASE_URL}/admin/create_knowledgebase/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setIsLoading(false);
        if (response.status === 200) {
          toast.success(response?.data?.message);
          fetchPdfData();
        }
        handleClose();
      } catch (err) {
        setIsLoading(false);
        toast.error(err?.response?.data?.error);
        handleClose();
      }
    } else {
      toast.info("Please select a PDF file.");
    }
  };

  return (
    <>
      <CreateChatBtn onClick={handleClickOpen} startIcon={<FiUpload />}>
        Upload File
      </CreateChatBtn>
      <StyledDialog open={open} onClose={handleClose}>
        <form onSubmit={handleUpload}>
          <StyledDialogTitle>Upload File</StyledDialogTitle>
          <StyledDialogContent>
            <DialogContentText>Upload Pdf File</DialogContentText>
            <StyledInput
              type='file'
              accept='application/pdf'
              onChange={handleFileChange}
            />
          </StyledDialogContent>
          <StyledDialogActions>
            <ButtonContainer>
              {isLoading ? (
                <CircularProgress
                  style={{
                    color: "var(--text-secondary)",
                    top: "25%",
                    right: "3%",
                    width: "2.2rem",
                    height: "2.2rem",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <>
                  <CreateChatBtn onClick={handleClose}>Cancel</CreateChatBtn>
                  <CreateChatBtn type='submit'>Upload</CreateChatBtn>
                </>
              )}
            </ButtonContainer>
          </StyledDialogActions>
        </form>
      </StyledDialog>
    </>
  );
}

const CreateChatBtn = styled(Button)`
  color: var(--text-secondary) !important;
  display: flex;
  align-items: center;
  justify-content: center !important;
  gap: 0rem;
  background: var(--highlighting-background) !important;
  padding: 0.65rem 2rem !important;
  font-size: 1.2rem !important;
  font-weight: bold;
  text-transform: none !important;
  border-radius: 0.5rem !important;
  border: 0.1rem solid var(--highlighting-background) !important;
  margin-bottom: 3rem !important;
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    min-width: 40rem;
  }
`;
const StyledInput = styled(Input)`
  width: 100%;
  margin-top: 1rem;
  font-size: 1.2rem;
`;

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: center;
  padding: 1.6rem;
`;

const StyledDialogTitle = styled(DialogTitle)`
  font-size: 1.5rem !important;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 1.6rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
