import { useEffect, useRef, useState } from "react";
import { IoCreateOutline } from "react-icons/io5";
import { RiDeleteBin7Line } from "react-icons/ri";
import MakeNewChat from "../ReusableComponents/MakeNewChat";
import ShowSelectedChat from "../ReusableComponents/ShowSelectedChat";
import {
  ChatHistoryButton,
  ChatContainer,
  ChatHistoryContainer,
  ConversationContainer,
  CreateChatBtn,
  StyledBtn,
  PopperDiv,
} from "../../Assets/Styles/Pages/CreateChat";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useSpeechRecognition from "../../hooks/useSpeechRecognition";
import getCurrentTimestamp from "../../Utils/TimeStampUtil";
import { useDispatch } from "react-redux";
import { BiMessageDots } from "react-icons/bi";
import { LuMoreHorizontal } from "react-icons/lu";
import { MdModeEdit } from "react-icons/md";
import { IoSaveOutline } from "react-icons/io5";
import { ImCancelCircle } from "react-icons/im";
import {
  insertHistory,
  flushHistory,
  deleteChat,
} from "../../Redux/chatHistorySlice";
import api from "../../Utils/API/ApiInterceptor";
import { usePopper } from "react-popper";
import {
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
} from "@mui/material";
import { decryptData } from "../../Utils/API/cryptoUtil";

const ChatHistoryItems = ({
  value,
  setNewChat,
  setSelected,
  setShouldAnimate,
  handleChatDelete,
}) => {
  const chatHistory = JSON.parse(sessionStorage.getItem("chat_history")) || [];
  const indexOfValue = chatHistory.findIndex((item) => item.id === value);
  const [heading, setHeading] = useState(chatHistory[indexOfValue].heading);
  const [popperVisible, setPopperVisible] = useState();
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const inputRef = useRef(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
  });

  const handleHeadingChange = async () => {
    if (!heading) {
      toast.error("Heading cannot be empty");
      return;
    }

    try {
      setIsLoading(true);
      const response = await api.post("/user/edit_chat_heading", {
        sessionId: value,
        chatHeading: heading,
        user_email: decryptData(sessionStorage.getItem("user")).email,
      });
      if (response.status === 200) {
        chatHistory[indexOfValue].heading = heading;
        sessionStorage.setItem("chat_history", JSON.stringify(chatHistory));
        setIsEdit(false);
        // toast.success(response?.data?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperElement && !popperElement.contains(event.target)) {
        setPopperVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popperElement]);

  if (chatHistory[indexOfValue]?.["messages"]?.length > 0) {
    const queryText = chatHistory[indexOfValue].heading;
    return (
      <>
        <ChatHistoryButton
          onClick={() => {
            setSelected(value);
            setNewChat(true);
            setShouldAnimate(false);
          }}
          ref={setReferenceElement}
        >
          <BiMessageDots style={{ fontSize: "1.5rem" }} />
          {isEdit ? (
            <>
              <Input
                style={{
                  marginLeft: "0.7rem",
                  color: "var(--text-secondary)",
                  fontSize: "1.2rem",
                }}
                value={heading}
                onChange={(e) => setHeading(e.target.value)}
                disableUnderline
                ref={inputRef}
                endAdornment={
                  <InputAdornment position="end">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {isLoading ? (
                        <div
                          style={{ flex: "0 0 auto", marginRight: "0.5rem" }}
                        >
                          <IconButton>
                            <CircularProgress
                              style={{
                                color: "var(--text-secondary)",
                                width: "2rem",
                                height: "2rem",
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                        </div>
                      ) : (
                        <div style={{ flex: "0 0 auto" }}>
                          <IconButton onClick={handleHeadingChange}>
                            <IoSaveOutline
                              style={{
                                color: "var(--text-secondary)",
                                width: "1.5rem",
                                height: "1.5rem",
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>

                          <IconButton
                            onClick={(e) => {
                              setIsEdit(false);
                              setPopperVisible(false);
                            }}
                          >
                            <ImCancelCircle
                              style={{
                                color: "var(--text-secondary)",
                                width: "1.5rem",
                                height: "1.5rem",
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </InputAdornment>
                }
              />
            </>
          ) : (
            <>
              <p style={{ marginLeft: "0.7rem" }}>
                {queryText?.length < 27
                  ? queryText
                  : `${queryText?.substr(0, 24)}...`}
              </p>
              <div
                className="action"
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <LuMoreHorizontal
                  style={{ fontSize: "1.5rem", cursor: "pointer" }}
                  onClick={(e) => setPopperVisible(!popperVisible)}
                />
              </div>
              {popperVisible && (
                <PopperDiv
                  style={{
                    ...styles.popper,
                    ...attributes.popper,
                  }}
                  forwardedRef={setPopperElement}
                >
                  <StyledBtn
                    onClick={(e) => {
                      setIsEdit(true);
                      if (inputRef.current) {
                        inputRef.current.focus();
                      }
                    }}
                  >
                    <MdModeEdit
                      style={{
                        color: "var(--text-secondary)",
                        fontSize: "1.5rem",
                      }}
                    />
                    <p>Edit</p>
                  </StyledBtn>
                  <StyledBtn
                    onClick={() => handleChatDelete(value, indexOfValue)}
                  >
                    <RiDeleteBin7Line
                      style={{
                        color: "var(--text-secondary)",
                        fontSize: "1.5rem",
                      }}
                    />
                    <p>Delete</p>
                  </StyledBtn>
                </PopperDiv>
              )}
            </>
          )}
        </ChatHistoryButton>
      </>
    );
  } else {
    return null;
  }
};

function CreateChat({
  NewChat,
  setNewChat,
  shouldAnimate,
  setShouldAnimate,
  selected,
  setSelected,
}) {
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const chatHistory = JSON.parse(sessionStorage.getItem("chat_history")) || [];
  const dispatch = useDispatch();

  while (chatHistory.length > 10) {
    chatHistory.pop();
  }

  const {
    text,
    isListening,
    startListening,
    stopListening,
    setIsListening,
    hasRecognitionSupport,
  } = useSpeechRecognition();
  const currentTimeStamp = getCurrentTimestamp();

  const location = useLocation();
  const navigate = useNavigate();
  const [hardRefresh, setHardRefresh] = useState(false);

  const handleChatDelete = async (value, indexOfValue) => {
    try {
      const response = await api.post("/user/delete_single_chat", {
        sessionId: value,
        user_email: decryptData(sessionStorage.getItem("user")).email,
      });
      if (response.status === 200) {
        setHardRefresh((prev) => !prev);
        dispatch(deleteChat(value));
        const updatedChatHistory =
          JSON.parse(sessionStorage.getItem("chat_history")) || [];
        updatedChatHistory.splice(indexOfValue, 1);
        sessionStorage.setItem(
          "chat_history",
          JSON.stringify(updatedChatHistory)
        );
        setNewChat(false);
        toast.success(response?.data?.message);
      }
    } catch (err) {
      toast.error(err?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (location?.state?.message) {
      toast.success(location?.state?.message);
      navigate({ state: { message: "" } });
    }
  }, [location, navigate]);

  useEffect(() => {
    const updatedHistory = chatHistory.map((thread) => {
      return thread.id;
    });

    dispatch(flushHistory());
    updatedHistory.forEach((id) => dispatch(insertHistory(id)));
  }, [chatHistory, dispatch]);

  return (
    <>
      <ChatContainer>
        <ChatHistoryContainer>
          <CreateChatBtn
            onClick={() => {
              setNewChat(false);
            }}
          >
            <IoCreateOutline
              style={{
                color: "var(--text-secondary)",
                fontSize: "1.5rem",
              }}
            />
            New Chat
          </CreateChatBtn>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {chatHistory?.map((thread) => {
              return (
                <ChatHistoryItems
                  setNewChat={setNewChat}
                  setSelected={setSelected}
                  setShouldAnimate={setShouldAnimate}
                  value={thread.id}
                  key={thread.id}
                  handleChatDelete={handleChatDelete}
                />
              );
            })}
          </div>
        </ChatHistoryContainer>
        <ConversationContainer>
          {!NewChat ? (
            <MakeNewChat
              setSelected={setSelected}
              setNewChat={setNewChat}
              setShouldAnimate={setShouldAnimate}
              query={query}
              setQuery={setQuery}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              text={text}
              isListening={isListening}
              startListening={startListening}
              stopListening={stopListening}
              setIsListening={setIsListening}
              hasRecognitionSupport={hasRecognitionSupport}
              currentTimeStamp={currentTimeStamp}
            />
          ) : (
            <ShowSelectedChat
              selected={selected}
              shouldAnimate={shouldAnimate}
              setSelected={setSelected}
              setNewChat={setNewChat}
              setShouldAnimate={setShouldAnimate}
              query={query}
              setQuery={setQuery}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              text={text}
              isListening={isListening}
              startListening={startListening}
              stopListening={stopListening}
              setIsListening={setIsListening}
              hasRecognitionSupport={hasRecognitionSupport}
            />
          )}
        </ConversationContainer>
      </ChatContainer>
    </>
  );
}

export default CreateChat;
