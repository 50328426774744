import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SESSION_STORAGE_ENCRYPTION_KEY;
const PASSWORD_KEY = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
const INITIALISATION_VECTOR = process.env.REACT_APP_INITIALIZED_VECTOR;

export const encryptData = (data) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();
  return encryptedData;
};

export const decryptData = (encryptedData) => {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export const encryptPassword = (password) => {
  const PASSWORD_KEY_UTF_8 = CryptoJS.enc.Utf8.parse(PASSWORD_KEY);
  const PASS_KEY = CryptoJS.enc.Utf8.parse(INITIALISATION_VECTOR);
  const encryptedData = CryptoJS.AES.encrypt(password, PASSWORD_KEY_UTF_8, {
    iv: PASS_KEY,
    mode: CryptoJS.mode.CBC,
  }).toString();
  return encryptedData;
};
