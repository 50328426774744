import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";

export default function AlertDialog({
  open,
  handleClose,
  handleConfirmation,
  actionType,
  content,
}) {
  const actionText =
    actionType === "remove"
      ? "Remove"
      : actionType === "restrict"
      ? "Restrict"
      : actionType === "unrestrict"
      ? "Unrestrict"
      : actionType === "changeRole"
      ? "Change Role"
      : null;

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <StyledDialogTitle id='alert-dialog-title'>
        Confirmation
      </StyledDialogTitle>
      <DialogContent>
        <StyledDialogContentText id='alert-dialog-description'>
          Are you sure you want to {actionText?.toLowerCase()}
          {actionText === "Change Role" ? " of this" : " this"} {content}?
        </StyledDialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={handleClose}>Cancel</StyledButton>
        <StyledButton onClick={() => handleConfirmation(actionType)}>
          {actionText}
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    width: 40rem;
    background-color: var(--background-shade-1) !important;
    color: var(--text-primary) !important;
  }
  @media (max-width: 76.8em) {
    width: 80%;
  }
`;

const StyledButton = styled(({ color, ...other }) => <Button {...other} />)`
  && {
    color: var(--text-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0rem;
    background: var(--highlighting-background);
    padding: 1rem 2.5rem;
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: none;
    border-radius: 0.5rem;
    border: 0.1rem solid var(--background);
  }
`;

const StyledDialogContentText = styled(DialogContentText)`
  && {
    font-size: 1.6rem;
    color: var(--text-secondary);
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  && {
    font-weight: bold;
    font-size: 1.8rem;
    color: var(--text-secondary);
  }
`;
