import React, { useRef, useState } from "react";
import Sidebar from "../ReusableComponents/Sidebar";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  LeftContainer,
  MainLayout,
  RightContainer,
} from "../../Assets/Styles/Pages/Dashboard";
import Profile from "./Profile";
import AdminSetting from "./Admin/AdminSetting";
import CreateChat from "./CreateChat";
import ManageUsers from "./Admin/ManageUsers";
import Files from "./Admin/Files";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { collapseSidebar } from "../../Redux/expandSidebarSlice";
// import Ratings from "./Admin/Ratings";
import ContactUs from "./ContactUs";
import AllQueries from "./Admin/AllQueries";

function Dashboard() {
  const isAdmin = false;
  const [toggleTheme, setToggleTheme] = useState("light");
  const expand = useSelector((state) => state.sidebar.sidebarState);
  const [NewChat, setNewChat] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const leftContainerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        leftContainerRef.current &&
        !leftContainerRef.current.contains(event.target)
      ) {
        dispatch(collapseSidebar());
      }
    };
    const handleResize = () => {
      if (window.innerWidth < 768) {
        document.addEventListener("touchstart", handleClickOutside);
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("touchstart", handleClickOutside);
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("touchstart", handleClickOutside);
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, leftContainerRef]);

  if (!sessionStorage.getItem("token")) {
    sessionStorage.clear();
    return <Navigate to={"/login"} />;
  }

  return (
    <MainLayout data-theme={toggleTheme}>
      <LeftContainer expand={expand} forwardedRef={leftContainerRef}>
        <Sidebar
          toggleTheme={toggleTheme}
          setToggleTheme={setToggleTheme}
          setNewChat={setNewChat}
          setSelected={setSelected}
          setShouldAnimate={setShouldAnimate}
        />
      </LeftContainer>

      <RightContainer expand={expand}>
        <Routes>
          <Route
            path='create-chat'
            element={
              <CreateChat
                setNewChat={setNewChat}
                setSelected={setSelected}
                setShouldAnimate={setShouldAnimate}
                NewChat={NewChat}
                selected={selected}
                shouldAnimate={shouldAnimate}
              />
            }
          ></Route>
          <Route path='setting' element={<AdminSetting />}>
            <Route path='files' element={<Files />}></Route>
            {/* <Route path='ratings' element={<Ratings />}></Route> */}
            <Route path='queries' element={<AllQueries />}></Route>
            <Route
              path='users'
              element={<ManageUsers toggleTheme={toggleTheme} />}
            ></Route>
            <Route index element={<Navigate replace to={"files"} />}></Route>
          </Route>
          <Route path='profile' element={<Profile />}></Route>
          <Route path='contact' element={<ContactUs />}></Route>

          <Route
            index
            element={<Navigate to={isAdmin ? "setting" : "create-chat"} />}
          />

          <Route path='*' element={<Navigate to={"/"} />}></Route>
        </Routes>
      </RightContainer>
    </MainLayout>
  );
}

export default Dashboard;
